import { gql } from '@apollo/client';

export default gql`
  query getItemsBySubcategory($subcategoryId: Float!) {
    itemSubcategoryItems(
      id: $subcategoryId
      filter: { where: { status: true } }
    ) {
      id
      name
      status
      price
      description
      diet
      spicy
      imageUrl
      upSellIds
      itemVariantsOrder
      productGroupId
      itemVariants {
        id,
        itemVariantOptions {
          id
          name
          price
          order
          isStandard
          description
        }
      }
      productGroup {
        name
      }
    }
  }
`;
